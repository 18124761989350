import React, { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { Provider, ProviderEnum } from '@dynamic-labs/sdk-api';

import { ConfirmModal } from '../../../../../components/ConfirmModal';

type Props = {
  currentDisablingProvider: Provider | undefined;
  currentEnablingProvider: Provider | undefined;
  onClickCancelChanges: VoidFunction;
  setShowEmbeddedWalletsChangeWarning: Dispatch<SetStateAction<boolean>>;
  walletsAmount: {
    coinbaseWaas: number;
    magic: number;
    turnkey: number;
  };
};

export const ExistingEmbeddedWalletsWarningModal = ({
  setShowEmbeddedWalletsChangeWarning,
  onClickCancelChanges,
  walletsAmount,
  currentDisablingProvider,
  currentEnablingProvider,
}: Props) => {
  const { t } = useTranslation();

  const getTextForProvider = (provider: ProviderEnum | undefined) => {
    switch (provider) {
      case ProviderEnum.CoinbaseWaas:
        return t(
          'integrations.wallets.providers.existing_wallets_embeddedWallets_change_warning.providers.coinbase',
        );
      case ProviderEnum.Turnkey:
        return t(
          'integrations.wallets.providers.existing_wallets_embeddedWallets_change_warning.providers.turnkey-old',
        );
      default:
        return '';
    }
  };

  const getMessage = () => {
    const amountOfWallets =
      currentDisablingProvider?.provider === ProviderEnum.CoinbaseWaas
        ? walletsAmount.coinbaseWaas
        : walletsAmount.turnkey;

    const i18nMessage = t(
      'integrations.wallets.providers.existing_wallets_embeddedWallets_change_warning.content',
      {
        customer_text:
          amountOfWallets > 1
            ? t(
                'integrations.wallets.providers.existing_wallets_embeddedWallets_change_warning.customer_text.other',
              )
            : t(
                'integrations.wallets.providers.existing_wallets_embeddedWallets_change_warning.customer_text.one',
              ),
        older_method: getTextForProvider(currentDisablingProvider?.provider),
        value: amountOfWallets.toString(),
      },
    ).toString();

    if (currentEnablingProvider) {
      return i18nMessage.replace(
        '{{optional_new_method_text}}',
        t(
          'integrations.wallets.providers.existing_wallets_embeddedWallets_change_warning.new_method_text',
          {
            new_method: getTextForProvider(currentEnablingProvider?.provider),
            older_method: getTextForProvider(
              currentDisablingProvider?.provider,
            ),
          },
        ),
      );
    }
    return i18nMessage.replace('{{optional_new_method_text}}', '');
  };

  return (
    <ConfirmModal
      cancelText={t(
        'integrations.wallets.providers.existing_wallets_embeddedWallets_change_warning.cancel',
      )}
      confirmationText={t(
        'integrations.wallets.providers.existing_wallets_embeddedWallets_change_warning.confirm',
      )}
      message={getMessage()}
      onConfirm={() => setShowEmbeddedWalletsChangeWarning(false)}
      onCancel={() => {
        setShowEmbeddedWalletsChangeWarning(false);
        onClickCancelChanges();
      }}
      className='!z-[32]'
      backdropClassName='!z-[31]'
      confirmationBtnVariant='primary'
      title={t(
        'integrations.wallets.providers.existing_wallets_embeddedWallets_change_warning.title',
      )}
    />
  );
};
