/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmbeddedWalletSecurityMethod,
    EmbeddedWalletSecurityMethodFromJSON,
    EmbeddedWalletSecurityMethodFromJSONTyped,
    EmbeddedWalletSecurityMethodToJSON,
} from './EmbeddedWalletSecurityMethod';
import {
    FeatureFlags,
    FeatureFlagsFromJSON,
    FeatureFlagsFromJSONTyped,
    FeatureFlagsToJSON,
} from './FeatureFlags';
import {
    Funding,
    FundingFromJSON,
    FundingFromJSONTyped,
    FundingToJSON,
} from './Funding';
import {
    MobileSettings,
    MobileSettingsFromJSON,
    MobileSettingsFromJSONTyped,
    MobileSettingsToJSON,
} from './MobileSettings';
import {
    NameServiceSdkSettings,
    NameServiceSdkSettingsFromJSON,
    NameServiceSdkSettingsFromJSONTyped,
    NameServiceSdkSettingsToJSON,
} from './NameServiceSdkSettings';
import {
    ProjectSettingsSdkAccountAbstraction,
    ProjectSettingsSdkAccountAbstractionFromJSON,
    ProjectSettingsSdkAccountAbstractionFromJSONTyped,
    ProjectSettingsSdkAccountAbstractionToJSON,
} from './ProjectSettingsSdkAccountAbstraction';
import {
    ProjectSettingsSdkEmailSignIn,
    ProjectSettingsSdkEmailSignInFromJSON,
    ProjectSettingsSdkEmailSignInFromJSONTyped,
    ProjectSettingsSdkEmailSignInToJSON,
} from './ProjectSettingsSdkEmailSignIn';
import {
    ProjectSettingsSdkEmbeddedWallets,
    ProjectSettingsSdkEmbeddedWalletsFromJSON,
    ProjectSettingsSdkEmbeddedWalletsFromJSONTyped,
    ProjectSettingsSdkEmbeddedWalletsToJSON,
} from './ProjectSettingsSdkEmbeddedWallets';
import {
    ProjectSettingsSdkSocialSignIn,
    ProjectSettingsSdkSocialSignInFromJSON,
    ProjectSettingsSdkSocialSignInFromJSONTyped,
    ProjectSettingsSdkSocialSignInToJSON,
} from './ProjectSettingsSdkSocialSignIn';
import {
    ProjectSettingsSdkWaas,
    ProjectSettingsSdkWaasFromJSON,
    ProjectSettingsSdkWaasFromJSONTyped,
    ProjectSettingsSdkWaasToJSON,
} from './ProjectSettingsSdkWaas';
import {
    ProjectSettingsSdkWalletConnect,
    ProjectSettingsSdkWalletConnectFromJSON,
    ProjectSettingsSdkWalletConnectFromJSONTyped,
    ProjectSettingsSdkWalletConnectToJSON,
} from './ProjectSettingsSdkWalletConnect';
import {
    SdkView,
    SdkViewFromJSON,
    SdkViewFromJSONTyped,
    SdkViewToJSON,
} from './SdkView';

/**
 * 
 * @export
 * @interface ProjectSettingsSdk
 */
export interface ProjectSettingsSdk {
    /**
     * 
     * @type {NameServiceSdkSettings}
     * @memberof ProjectSettingsSdk
     */
    nameService?: NameServiceSdkSettings;
    /**
     * 
     * @type {FeatureFlags}
     * @memberof ProjectSettingsSdk
     */
    featureFlags?: FeatureFlags;
    /**
     * 
     * @type {ProjectSettingsSdkEmailSignIn}
     * @memberof ProjectSettingsSdk
     */
    emailSignIn?: ProjectSettingsSdkEmailSignIn;
    /**
     * 
     * @type {ProjectSettingsSdkSocialSignIn}
     * @memberof ProjectSettingsSdk
     */
    socialSignIn?: ProjectSettingsSdkSocialSignIn;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    multiWallet?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    multiWalletUnlinkDisabled?: boolean;
    /**
     * 
     * @type {MobileSettings}
     * @memberof ProjectSettingsSdk
     */
    mobile?: MobileSettings;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    confirmWalletTransfers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    onrampFunding?: boolean;
    /**
     * passkeyEmbeddedWalletEnabled is deprecated as of v0.19. Please enable embedded wallets using the provider API.
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    passkeyEmbeddedWalletEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    automaticEmbeddedWalletCreation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    passkeyEmbeddedWalletRecoveryEnabled?: boolean;
    /**
     * Embedded wallet authenticator security methods required upon creation of the embedded wallet at onboarding
     * @type {Array<EmbeddedWalletSecurityMethod>}
     * @memberof ProjectSettingsSdk
     */
    embeddedWalletSecurityMethods?: Array<EmbeddedWalletSecurityMethod>;
    /**
     * 
     * @type {ProjectSettingsSdkEmbeddedWallets}
     * @memberof ProjectSettingsSdk
     */
    embeddedWallets?: ProjectSettingsSdkEmbeddedWallets;
    /**
     * 
     * @type {ProjectSettingsSdkWaas}
     * @memberof ProjectSettingsSdk
     */
    waas?: ProjectSettingsSdkWaas;
    /**
     * 
     * @type {ProjectSettingsSdkWalletConnect}
     * @memberof ProjectSettingsSdk
     */
    walletConnect?: ProjectSettingsSdkWalletConnect;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    confirmEmailProviderForVerify?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    displayDynamicMessaging?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    hideNetworkInDynamicWidget?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    preventOrphanedAccounts?: boolean;
    /**
     * Configs used to create the views in the sdk.
     * @type {Array<SdkView>}
     * @memberof ProjectSettingsSdk
     */
    views?: Array<SdkView>;
    /**
     * 
     * @type {ProjectSettingsSdkAccountAbstraction}
     * @memberof ProjectSettingsSdk
     */
    accountAbstraction?: ProjectSettingsSdkAccountAbstraction;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    blockEmailSubaddresses?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    enableMultiAsset?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdk
     */
    showFiat?: boolean;
    /**
     * Ids of wallet connectors to filter out from available options in the sdk
     * @type {Array<string>}
     * @memberof ProjectSettingsSdk
     */
    disabledWalletConnectors?: Array<string>;
    /**
     * 
     * @type {Funding}
     * @memberof ProjectSettingsSdk
     */
    funding?: Funding;
}

export function ProjectSettingsSdkFromJSON(json: any): ProjectSettingsSdk {
    return ProjectSettingsSdkFromJSONTyped(json, false);
}

export function ProjectSettingsSdkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsSdk {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nameService': !exists(json, 'nameService') ? undefined : NameServiceSdkSettingsFromJSON(json['nameService']),
        'featureFlags': !exists(json, 'featureFlags') ? undefined : FeatureFlagsFromJSON(json['featureFlags']),
        'emailSignIn': !exists(json, 'emailSignIn') ? undefined : ProjectSettingsSdkEmailSignInFromJSON(json['emailSignIn']),
        'socialSignIn': !exists(json, 'socialSignIn') ? undefined : ProjectSettingsSdkSocialSignInFromJSON(json['socialSignIn']),
        'multiWallet': !exists(json, 'multiWallet') ? undefined : json['multiWallet'],
        'multiWalletUnlinkDisabled': !exists(json, 'multiWalletUnlinkDisabled') ? undefined : json['multiWalletUnlinkDisabled'],
        'mobile': !exists(json, 'mobile') ? undefined : MobileSettingsFromJSON(json['mobile']),
        'confirmWalletTransfers': !exists(json, 'confirmWalletTransfers') ? undefined : json['confirmWalletTransfers'],
        'onrampFunding': !exists(json, 'onrampFunding') ? undefined : json['onrampFunding'],
        'passkeyEmbeddedWalletEnabled': !exists(json, 'passkeyEmbeddedWalletEnabled') ? undefined : json['passkeyEmbeddedWalletEnabled'],
        'automaticEmbeddedWalletCreation': !exists(json, 'automaticEmbeddedWalletCreation') ? undefined : json['automaticEmbeddedWalletCreation'],
        'passkeyEmbeddedWalletRecoveryEnabled': !exists(json, 'passkeyEmbeddedWalletRecoveryEnabled') ? undefined : json['passkeyEmbeddedWalletRecoveryEnabled'],
        'embeddedWalletSecurityMethods': !exists(json, 'embeddedWalletSecurityMethods') ? undefined : ((json['embeddedWalletSecurityMethods'] as Array<any>).map(EmbeddedWalletSecurityMethodFromJSON)),
        'embeddedWallets': !exists(json, 'embeddedWallets') ? undefined : ProjectSettingsSdkEmbeddedWalletsFromJSON(json['embeddedWallets']),
        'waas': !exists(json, 'waas') ? undefined : ProjectSettingsSdkWaasFromJSON(json['waas']),
        'walletConnect': !exists(json, 'walletConnect') ? undefined : ProjectSettingsSdkWalletConnectFromJSON(json['walletConnect']),
        'confirmEmailProviderForVerify': !exists(json, 'confirmEmailProviderForVerify') ? undefined : json['confirmEmailProviderForVerify'],
        'displayDynamicMessaging': !exists(json, 'displayDynamicMessaging') ? undefined : json['displayDynamicMessaging'],
        'hideNetworkInDynamicWidget': !exists(json, 'hideNetworkInDynamicWidget') ? undefined : json['hideNetworkInDynamicWidget'],
        'preventOrphanedAccounts': !exists(json, 'preventOrphanedAccounts') ? undefined : json['preventOrphanedAccounts'],
        'views': !exists(json, 'views') ? undefined : ((json['views'] as Array<any>).map(SdkViewFromJSON)),
        'accountAbstraction': !exists(json, 'accountAbstraction') ? undefined : ProjectSettingsSdkAccountAbstractionFromJSON(json['accountAbstraction']),
        'blockEmailSubaddresses': !exists(json, 'blockEmailSubaddresses') ? undefined : json['blockEmailSubaddresses'],
        'enableMultiAsset': !exists(json, 'enableMultiAsset') ? undefined : json['enableMultiAsset'],
        'showFiat': !exists(json, 'showFiat') ? undefined : json['showFiat'],
        'disabledWalletConnectors': !exists(json, 'disabledWalletConnectors') ? undefined : json['disabledWalletConnectors'],
        'funding': !exists(json, 'funding') ? undefined : FundingFromJSON(json['funding']),
    };
}

export function ProjectSettingsSdkToJSON(value?: ProjectSettingsSdk | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nameService': NameServiceSdkSettingsToJSON(value.nameService),
        'featureFlags': FeatureFlagsToJSON(value.featureFlags),
        'emailSignIn': ProjectSettingsSdkEmailSignInToJSON(value.emailSignIn),
        'socialSignIn': ProjectSettingsSdkSocialSignInToJSON(value.socialSignIn),
        'multiWallet': value.multiWallet,
        'multiWalletUnlinkDisabled': value.multiWalletUnlinkDisabled,
        'mobile': MobileSettingsToJSON(value.mobile),
        'confirmWalletTransfers': value.confirmWalletTransfers,
        'onrampFunding': value.onrampFunding,
        'passkeyEmbeddedWalletEnabled': value.passkeyEmbeddedWalletEnabled,
        'automaticEmbeddedWalletCreation': value.automaticEmbeddedWalletCreation,
        'passkeyEmbeddedWalletRecoveryEnabled': value.passkeyEmbeddedWalletRecoveryEnabled,
        'embeddedWalletSecurityMethods': value.embeddedWalletSecurityMethods === undefined ? undefined : ((value.embeddedWalletSecurityMethods as Array<any>).map(EmbeddedWalletSecurityMethodToJSON)),
        'embeddedWallets': ProjectSettingsSdkEmbeddedWalletsToJSON(value.embeddedWallets),
        'waas': ProjectSettingsSdkWaasToJSON(value.waas),
        'walletConnect': ProjectSettingsSdkWalletConnectToJSON(value.walletConnect),
        'confirmEmailProviderForVerify': value.confirmEmailProviderForVerify,
        'displayDynamicMessaging': value.displayDynamicMessaging,
        'hideNetworkInDynamicWidget': value.hideNetworkInDynamicWidget,
        'preventOrphanedAccounts': value.preventOrphanedAccounts,
        'views': value.views === undefined ? undefined : ((value.views as Array<any>).map(SdkViewToJSON)),
        'accountAbstraction': ProjectSettingsSdkAccountAbstractionToJSON(value.accountAbstraction),
        'blockEmailSubaddresses': value.blockEmailSubaddresses,
        'enableMultiAsset': value.enableMultiAsset,
        'showFiat': value.showFiat,
        'disabledWalletConnectors': value.disabledWalletConnectors,
        'funding': FundingToJSON(value.funding),
    };
}

