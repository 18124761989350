import { chainsData } from '../../../../data/integrations';

export type IntegrationsFlags =
  | 'enableBitcoinIntegration'
  | 'enableEclipse'
  | 'enableSuiDashboard';

/** Maps an integration name to the corresponding name of the feature flag that should enable/disable it  */
export const mapIntegrationToFeatureFlag: Record<
  (typeof chainsData)[number]['name'],
  IntegrationsFlags
> = {
  bitcoin: 'enableBitcoinIntegration',
  eclipse: 'enableEclipse',
  sui: 'enableSuiDashboard',
};

export const isIntegrationEnabled = (
  integrationName: (typeof chainsData)[number]['name'],
  featureFlags: Record<IntegrationsFlags, boolean>,
) =>
  // Either the integration doesn't have a feature flag
  !(integrationName in mapIntegrationToFeatureFlag) ||
  // Or it's feature flag is true
  featureFlags[mapIntegrationToFeatureFlag[integrationName]];
