import {
  getChainIcon,
  getChainDisplayName,
} from '@dynamic-labs/sdk-react-core';

import styles from './chain-name.module.css';

interface Props {
  className?: string;
  hideDisplayName?: boolean;
  name: string;
}
export const ChainName = ({
  name,
  className,
  hideDisplayName = false,
}: Props): JSX.Element => {
  const ChainIcon = getChainIcon(name);
  return (
    <div className={styles.container}>
      <ChainIcon className={`${styles.chain_icon} ${className}`} />
      {!hideDisplayName && getChainDisplayName(name)}
    </div>
  );
};
