/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ThresholdSignatureScheme,
    ThresholdSignatureSchemeFromJSON,
    ThresholdSignatureSchemeFromJSONTyped,
    ThresholdSignatureSchemeToJSON,
} from './ThresholdSignatureScheme';

/**
 * 
 * @export
 * @interface ReshareRequest
 */
export interface ReshareRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReshareRequest
     */
    clientKeygenIds: Array<string>;
    /**
     * 
     * @type {ThresholdSignatureScheme}
     * @memberof ReshareRequest
     */
    oldThresholdSignatureScheme: ThresholdSignatureScheme;
    /**
     * 
     * @type {ThresholdSignatureScheme}
     * @memberof ReshareRequest
     */
    newThresholdSignatureScheme: ThresholdSignatureScheme;
}

export function ReshareRequestFromJSON(json: any): ReshareRequest {
    return ReshareRequestFromJSONTyped(json, false);
}

export function ReshareRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReshareRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientKeygenIds': json['clientKeygenIds'],
        'oldThresholdSignatureScheme': ThresholdSignatureSchemeFromJSON(json['oldThresholdSignatureScheme']),
        'newThresholdSignatureScheme': ThresholdSignatureSchemeFromJSON(json['newThresholdSignatureScheme']),
    };
}

export function ReshareRequestToJSON(value?: ReshareRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientKeygenIds': value.clientKeygenIds,
        'oldThresholdSignatureScheme': ThresholdSignatureSchemeToJSON(value.oldThresholdSignatureScheme),
        'newThresholdSignatureScheme': ThresholdSignatureSchemeToJSON(value.newThresholdSignatureScheme),
    };
}

