/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GlobalWalletSettings
 */
export interface GlobalWalletSettings {
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettings
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettings
     */
    walletName: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettings
     */
    popupPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettings
     */
    walletIconUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettings
     */
    customCssUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettings
     */
    termsOfServiceUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettings
     */
    privacyPolicyUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettings
     */
    termsOfServiceAndPrivacyPolicyMarkdown?: string;
    /**
     * If global wallet is enabled, then this timestamp will be present.
     * @type {Date}
     * @memberof GlobalWalletSettings
     */
    enabledAt?: Date | null;
}

export function GlobalWalletSettingsFromJSON(json: any): GlobalWalletSettings {
    return GlobalWalletSettingsFromJSONTyped(json, false);
}

export function GlobalWalletSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalWalletSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'walletName': json['walletName'],
        'popupPageTitle': !exists(json, 'popupPageTitle') ? undefined : json['popupPageTitle'],
        'walletIconUrl': !exists(json, 'walletIconUrl') ? undefined : json['walletIconUrl'],
        'customCssUrl': !exists(json, 'customCssUrl') ? undefined : json['customCssUrl'],
        'termsOfServiceUrl': !exists(json, 'termsOfServiceUrl') ? undefined : json['termsOfServiceUrl'],
        'privacyPolicyUrl': !exists(json, 'privacyPolicyUrl') ? undefined : json['privacyPolicyUrl'],
        'termsOfServiceAndPrivacyPolicyMarkdown': !exists(json, 'termsOfServiceAndPrivacyPolicyMarkdown') ? undefined : json['termsOfServiceAndPrivacyPolicyMarkdown'],
        'enabledAt': !exists(json, 'enabledAt') ? undefined : (json['enabledAt'] === null ? null : new Date(json['enabledAt'])),
    };
}

export function GlobalWalletSettingsToJSON(value?: GlobalWalletSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'walletName': value.walletName,
        'popupPageTitle': value.popupPageTitle,
        'walletIconUrl': value.walletIconUrl,
        'customCssUrl': value.customCssUrl,
        'termsOfServiceUrl': value.termsOfServiceUrl,
        'privacyPolicyUrl': value.privacyPolicyUrl,
        'termsOfServiceAndPrivacyPolicyMarkdown': value.termsOfServiceAndPrivacyPolicyMarkdown,
        'enabledAt': value.enabledAt === undefined ? undefined : (value.enabledAt === null ? null : value.enabledAt.toISOString()),
    };
}

