import { FC, useRef, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { AnimatePresence } from 'framer-motion';

import { Alert, Section } from '@dynamic-labs/northstar';
import {
  CustomHostnameStatusEnum,
  CustomHostnameUsageEnum,
} from '@dynamic-labs/sdk-api';
import { useGetCustomHostnames } from '@dynamic-labs/redcoast-query';

import { useGlobalWallets } from '../../../../app/hooks/useGlobalWallets';
import { useEnvironmentId } from '../../../../app/routes/Configurations/Providers/hooks';
import { ManageCustomDomain, SaveBanner } from '../../../components';

import { WalletInformation } from './WalletInformation';
import { GlobalWalletToggle } from './GlobalWalletToggle';
import styles from './GlobalWalletDomainSection.module.scss';
import { getGlobalWalletSettingsValidationSchema } from './getGlobalWalletSettingsValidationSchema';
import { GlobalWalletConfig } from './types';
import { TermsOfServiceAndPrivicyPolicy } from './TermsOfServiceAndPrivicyPolicy';

export const GlobalWalletDomainSection: FC = () => {
  const { t } = useTranslation();

  const environmentId = useEnvironmentId();

  const { data: hostnames } = useGetCustomHostnames(
    { environmentId: environmentId || '' },
    {
      enabled: !!environmentId,
    },
  );

  const globalWalletActiveHostname = useMemo(
    () =>
      hostnames?.customHostnames.find(
        (hostname) =>
          hostname.usage === CustomHostnameUsageEnum.GlobalWallet &&
          hostname.status === CustomHostnameStatusEnum.Active,
      ),
    [hostnames],
  );

  const {
    globalWalletSettings,
    createGlobalWalletSettings,
    updateGlobalWalletSettings,
    disableGlobalWallet,
    enableGlobalWallet,
    isLoading,
    isSaving,
    error: apiError,
  } = useGlobalWallets(environmentId || '');

  const formikRef = useRef<FormikProps<GlobalWalletConfig>>(null);

  const initialValues: GlobalWalletConfig = useMemo(
    () => ({
      customCssUrl: globalWalletSettings?.customCssUrl ?? '',
      enabled: !!globalWalletSettings?.enabledAt,
      popupPageTitle: globalWalletSettings?.popupPageTitle ?? '',
      privacyPolicyUrl: globalWalletSettings?.privacyPolicyUrl ?? '',
      termsOfServiceAndPrivacyPolicyMarkdown:
        globalWalletSettings?.termsOfServiceAndPrivacyPolicyMarkdown ?? '',
      termsOfServiceUrl: globalWalletSettings?.termsOfServiceUrl ?? '',
      walletIconUrl: globalWalletSettings?.walletIconUrl ?? '',
      walletName: globalWalletSettings?.walletName ?? '',
    }),
    [globalWalletSettings],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onGlobalWalletConfigChange = async (newConfig: GlobalWalletConfig) => {
    const settingsChanged =
      newConfig.customCssUrl !== initialValues.customCssUrl ||
      newConfig.walletIconUrl !== initialValues.walletIconUrl ||
      newConfig.popupPageTitle !== initialValues.popupPageTitle ||
      newConfig.walletName !== initialValues.walletName ||
      newConfig.termsOfServiceUrl !== initialValues.termsOfServiceUrl ||
      newConfig.privacyPolicyUrl !== initialValues.privacyPolicyUrl ||
      newConfig.termsOfServiceAndPrivacyPolicyMarkdown !==
        initialValues.termsOfServiceAndPrivacyPolicyMarkdown;

    // create or update global wallet settings
    if (settingsChanged) {
      const settings = {
        customCssUrl: newConfig.customCssUrl,
        popupPageTitle: newConfig.popupPageTitle,
        privacyPolicyUrl: newConfig.privacyPolicyUrl,
        termsOfServiceAndPrivacyPolicyMarkdown:
          newConfig.termsOfServiceAndPrivacyPolicyMarkdown,
        termsOfServiceUrl: newConfig.termsOfServiceUrl,
        walletIconUrl: newConfig.walletIconUrl,
        walletName: newConfig.walletName,
      };

      await upsertSettings(settings);
    }

    const toggleChanged = newConfig.enabled !== initialValues.enabled;

    // enable/disable global wallet
    if (toggleChanged) {
      await toggleGlobalWallet(newConfig.enabled);
    }
  };

  const upsertSettings = async (
    settings: Omit<GlobalWalletConfig, 'enabled'>,
  ) => {
    if (globalWalletSettings) {
      await updateGlobalWalletSettings(settings);
    } else {
      await createGlobalWalletSettings(settings);
    }
  };

  const toggleGlobalWallet = async (enabled: boolean) => {
    if (enabled) {
      await enableGlobalWallet();
    } else {
      await disableGlobalWallet();
    }
  };

  const handleCancelClick = () => {
    formikRef.current?.resetForm();
  };

  const hasGlobalWalletConfigChanged = (values: GlobalWalletConfig) =>
    Object.keys(values).some(
      (key) =>
        values[key as keyof GlobalWalletConfig] !==
        initialValues[key as keyof GlobalWalletConfig],
    );

  return (
    <Section
      title={t('v2.page.global_wallets.domain_and_wallet_info.title')}
      description={t(
        'v2.page.global_wallets.domain_and_wallet_info.description',
      )}
    >
      <Formik
        onSubmit={onGlobalWalletConfigChange}
        initialValues={initialValues}
        enableReinitialize
        innerRef={formikRef}
        validationSchema={getGlobalWalletSettingsValidationSchema(t)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, handleSubmit }) => (
          <Form>
            <div className={styles.wrapper}>
              {!!apiError && (
                <Alert
                  title={t(
                    'v2.page.global_wallets.domain_and_wallet_info.unexpected_error.title',
                  )}
                  description={t(
                    'v2.page.global_wallets.domain_and_wallet_info.unexpected_error.description',
                  )}
                  variant='error'
                />
              )}
              <GlobalWalletToggle
                isEnabled={!!globalWalletActiveHostname}
                isLoading={isLoading || isSaving}
              />
              {values.enabled && (
                <AnimatePresence>
                  <div className={styles.settings}>
                    {globalWalletActiveHostname && (
                      <ManageCustomDomain
                        domain={globalWalletActiveHostname.hostname}
                        title={t(
                          'v2.page.global_wallets.domain_and_wallet_info.domain.title',
                        )}
                        description={t(
                          'v2.page.global_wallets.domain_and_wallet_info.domain.description',
                        )}
                      />
                    )}
                    <WalletInformation />
                    <TermsOfServiceAndPrivicyPolicy
                      hasCustomMarkdown={
                        !!values.termsOfServiceAndPrivacyPolicyMarkdown
                      }
                    />
                  </div>
                </AnimatePresence>
              )}
              <AnimatePresence>
                {hasGlobalWalletConfigChanged(values) && (
                  <SaveBanner
                    handleOnSave={handleSubmit}
                    handleOnCancel={handleCancelClick}
                    isLoading={false}
                  />
                )}
              </AnimatePresence>
            </div>
          </Form>
        )}
      </Formik>
    </Section>
  );
};
