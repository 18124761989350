import { useState } from 'react';

import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { number, object, string, TestContext, ValidationError } from 'yup';

import { PostAllowlistEntriesRequest } from '@dynamic-labs/sdk-api';

import { Select } from '../../../../../components/Select';
import { Typography } from '../../../../../components/Typography';
import { ErrorInfo } from '../../../../../components/ErrorInfo';
import { PlusIcon } from '../../../../../../icons';
import Button from '../../../../../components/Button';
import { allowListsApi } from '../../../../../services/api';
import Input from '../../../../../components/Input';
import { Icon } from '../../../../../components/Icon';

import { FormErrors } from './FormErrors';
import styles from './AccessListEntryForm.module.css';

type AccessListEntryFormValues = {
  address: string;
  alias: string;
  discordUsername: string;
  email: string;
  emailDomain: string;
  farcasterFid: number;
  farcasterUsername: string;
  phoneNumber: string;
  twitterUsername: string;
};

type AccessListEntryFormProps = {
  accessListId?: string;
  onCreateSuccess: () => void;
};

type ErrorMessage = {
  message: string;
  title: string;
};

type AtLeastOneOfRequiredType = (
  value: string | number | null | undefined,
  context: TestContext,
) => boolean | ValidationError;

type AllowListEntryType =
  | 'email'
  | 'emailDomain'
  | 'wallet'
  | 'phoneNumber'
  | 'discordUsername'
  | 'twitterUsername'
  | 'farcasterUsername'
  | 'farcasterFid';

const atLeastOneOfRequired =
  (otherFields: string[]): AtLeastOneOfRequiredType =>
  (value, context) => {
    const { path, parent, createError } = context;
    const isFieldEmpty = otherFields.every((field) => !value && !parent[field]);
    if (isFieldEmpty) {
      return createError({
        message: 'required',
        path,
      });
    }
    return true;
  };

export const AccessListEntryForm = ({
  accessListId,
  onCreateSuccess,
}: AccessListEntryFormProps) => {
  const [allowlistEntryType, setAllowlistEntryType] =
    useState<AllowListEntryType>('email');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorMessage | undefined>(undefined);

  const { t } = useTranslation();
  const onSubmit = async (
    values: AccessListEntryFormValues,
    actions: { resetForm: () => void },
  ) => {
    try {
      setLoading(true);
      if (!accessListId) return;
      const postAllowlistEntriesRequest: PostAllowlistEntriesRequest = {};

      if (values.alias) {
        postAllowlistEntriesRequest.alias = values.alias;
      }

      if (values.email) {
        postAllowlistEntriesRequest.email = values.email;
      }

      if (values.emailDomain) {
        postAllowlistEntriesRequest.emailDomain = values.emailDomain;
      }

      if (values.phoneNumber) {
        postAllowlistEntriesRequest.phoneNumber = `${
          values.phoneNumber.startsWith('+') ? '' : '+'
        }${values.phoneNumber}`;
      }

      if (values.discordUsername) {
        postAllowlistEntriesRequest.discordUsername = values.discordUsername;
      }

      if (values.twitterUsername) {
        postAllowlistEntriesRequest.twitterUsername = values.twitterUsername;
      }

      if (values.farcasterUsername) {
        postAllowlistEntriesRequest.farcasterUsername =
          values.farcasterUsername;
      }

      if (values.farcasterFid) {
        postAllowlistEntriesRequest.farcasterFid = Number(values.farcasterFid);
      }

      if (values.address) {
        postAllowlistEntriesRequest.walletPublicKey = values.address;
      }

      const entry = await allowListsApi.postEntryByAllowlistId({
        allowlistId: accessListId,
        postAllowlistEntriesRequest,
      });
      if (!entry?.id) return;
      actions.resetForm();
      setError(undefined);
    } catch (e: any) {
      let message;
      let title;
      if (e.status === 422) {
        message = t(
          'integrations.onboarding_and_kyc.access_control.access_list.duplicate_address_error',
        );
        title = t(
          'integrations.onboarding_and_kyc.access_control.access_list.duplicate_address_error_title',
        );
      } else {
        message = t(
          'integrations.onboarding_and_kyc.access_control.errors.unexpected_error',
        );
        title = t(
          'integrations.onboarding_and_kyc.access_control.errors.unexpected_error_title',
        );
      }
      setError({ message, title });
    } finally {
      onCreateSuccess();
      setLoading(false);
    }
  };

  const formDisabled = accessListId === undefined || accessListId === '';

  const validationSchema = object().shape({
    address: string()
      .nullable()
      .test(
        'addressRequired',
        'required',
        atLeastOneOfRequired([
          'email',
          'emailDomain',
          'phoneNumber',
          'discordUsername',
          'twitterUsername',
          'farcasterUsername',
          'farcasterFid',
        ]),
      ),
    discordUsername: string()
      .nullable()
      .test(
        'discordUsernameRequired',
        'required',
        atLeastOneOfRequired([
          'address',
          'email',
          'emailDomain',
          'phoneNumber',
          'twitterUsername',
          'farcasterUsername',
          'farcasterFid',
        ]),
      ),
    email: string()
      .nullable()
      .email('invalidFormat')
      .test(
        'emailRequired',
        'required',
        atLeastOneOfRequired([
          'address',
          'phoneNumber',
          'emailDomain',
          'discordUsername',
          'twitterUsername',
          'farcasterUsername',
          'farcasterFid',
        ]),
      ),
    emailDomain: string()
      .nullable()
      .matches(/^(?!https?:\/\/).+$/, 'invalidFormat')
      .test(
        'emailDomainRequired',
        'required',
        atLeastOneOfRequired([
          'address',
          'email',
          'phoneNumber',
          'discordUsername',
          'twitterUsername',
          'farcasterUsername',
          'farcasterFid',
        ]),
      ),
    farcasterFid: number()
      .nullable()
      .typeError('invalidNumber')
      .test(
        'farcasterFidRequired',
        'required',
        atLeastOneOfRequired([
          'address',
          'email',
          'emailDomain',
          'phoneNumber',
          'discordUsername',
          'twitterUsername',
          'farcasterUsername',
        ]),
      ),
    farcasterUsername: string()
      .nullable()
      .test(
        'farcasterUsernameRequired',
        'required',
        atLeastOneOfRequired([
          'address',
          'email',
          'emailDomain',
          'phoneNumber',
          'discordUsername',
          'twitterUsername',
          'farcasterFid',
        ]),
      ),
    phoneNumber: string()
      .nullable()
      .test(
        'phoneNumberRequired',
        'required',
        atLeastOneOfRequired([
          'address',
          'email',
          'emailDomain',
          'discordUsername',
          'twitterUsername',
          'farcasterUsername',
          'farcasterFid',
        ]),
      ),
    twitterUsername: string()
      .nullable()
      .test(
        'twitterUsernameRequired',
        'required',
        atLeastOneOfRequired([
          'address',
          'email',
          'emailDomain',
          'phoneNumber',
          'discordUsername',
          'farcasterUsername',
          'farcasterFid',
        ]),
      ),
  });

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        address: '',
        alias: '',
        discordUsername: '',
        email: '',
        emailDomain: '',
        farcasterFid: 0,
        farcasterUsername: '',
        phoneNumber: '',
        twitterUsername: '',
      }}
      validationSchema={validationSchema}
    >
      {({ errors, touched }) => (
        <Form>
          {error && (
            <ErrorInfo className='mb-5'>
              <span>
                <Typography
                  className={styles.errorHeading}
                  variant='paragraph-2'
                  weight='medium'
                >
                  {error?.title}
                </Typography>
                <Typography
                  className={styles.errorMessage}
                  variant='paragraph-1'
                >
                  {error?.message}
                </Typography>
              </span>
            </ErrorInfo>
          )}
          <FormErrors
            errors={errors}
            showErrors={
              !!Object.keys(touched).length && !!Object.keys(errors).length
            }
          />
          <div className={styles.form__row}>
            <Select
              dropdownTriggerRootClassName='min-h-[48px]'
              className='select-emailOrWallet min-w-[100px]'
              onSelect={(value) =>
                setAllowlistEntryType(value as AllowListEntryType)
              }
              options={[
                'email',
                'emailDomain',
                'wallet',
                'phoneNumber',
                'discordUsername',
                'twitterUsername',
                'farcasterUsername',
                'farcasterFid',
              ].map((option: string) => (
                <Select.Item value={option}>
                  <>
                    {t(
                      `integrations.onboarding_and_kyc.access_control.access_list.forms.selectAllowlistInput.${
                        option as AllowListEntryType
                      }`,
                    )}
                  </>
                </Select.Item>
              ))}
              value={allowlistEntryType}
            />

            {allowlistEntryType === 'email' && (
              <Field
                as={Input}
                name='email'
                id='email'
                label={t(
                  'integrations.onboarding_and_kyc.access_control.access_list.forms.emailLabel',
                )}
                error={!!errors.email && !!touched.email}
                disabled={formDisabled}
              />
            )}

            {allowlistEntryType === 'wallet' && (
              <Field
                as={Input}
                name='address'
                id='address'
                label={t(
                  'integrations.onboarding_and_kyc.access_control.access_list.forms.addressLabel',
                )}
                error={!!errors.address && !!touched.address}
                disabled={formDisabled}
              />
            )}

            {allowlistEntryType === 'phoneNumber' && (
              <Field
                as={Input}
                name='phoneNumber'
                id='phoneNumber'
                label={t(
                  'integrations.onboarding_and_kyc.access_control.access_list.forms.phoneNumberLabel',
                )}
                error={!!errors.phoneNumber && !!touched.phoneNumber}
                disabled={formDisabled}
              />
            )}

            {allowlistEntryType === 'discordUsername' && (
              <Field
                as={Input}
                name='discordUsername'
                id='discordUsername'
                label={t(
                  'integrations.onboarding_and_kyc.access_control.access_list.forms.discordUsernameLabel',
                )}
                error={!!errors.discordUsername && !!touched.discordUsername}
                disabled={formDisabled}
              />
            )}

            {allowlistEntryType === 'twitterUsername' && (
              <Field
                as={Input}
                name='twitterUsername'
                id='twitterUsername'
                label={t(
                  'integrations.onboarding_and_kyc.access_control.access_list.forms.twitterUsernameLabel',
                )}
                error={!!errors.twitterUsername && !!touched.twitterUsername}
                disabled={formDisabled}
              />
            )}

            {allowlistEntryType === 'farcasterUsername' && (
              <Field
                as={Input}
                name='farcasterUsername'
                id='farcasterUsername'
                label={t(
                  'integrations.onboarding_and_kyc.access_control.access_list.forms.farcasterUsernameLabel',
                )}
                error={
                  !!errors.farcasterUsername && !!touched.farcasterUsername
                }
                disabled={formDisabled}
              />
            )}

            {allowlistEntryType === 'farcasterFid' && (
              <Field
                as={Input}
                name='farcasterFid'
                id='farcasterFid'
                label={t(
                  'integrations.onboarding_and_kyc.access_control.access_list.forms.farcasterFidLabel',
                )}
                error={!!errors.farcasterFid && !!touched.farcasterFid}
                disabled={formDisabled}
              />
            )}

            {allowlistEntryType === 'emailDomain' && (
              <Field
                as={Input}
                name='emailDomain'
                id='emailDomain'
                label={t(
                  'integrations.onboarding_and_kyc.access_control.access_list.forms.emailDomainLabel',
                )}
                error={!!errors.emailDomain && !!touched.emailDomain}
                disabled={formDisabled}
              />
            )}

            <Field
              as={Input}
              name='alias'
              id='alias'
              label={t(
                'integrations.onboarding_and_kyc.access_control.access_list.forms.aliasLabel',
              )}
              disabled={formDisabled}
            />
          </div>
          <div className={styles.buttons}>
            <Button
              variant='secondary'
              type='submit'
              loading={loading}
              disabled={formDisabled}
              leading={<Icon size='medium' icon={<PlusIcon />} />}
            >
              {t(
                'integrations.onboarding_and_kyc.access_control.access_list.add_new_list_entry_button',
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AccessListEntryForm;
