import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';

import { SingleToggleCard, Input } from '@dynamic-labs/northstar';

import styles from '../GlobalWalletDomainSection.module.scss';
import { ConfigItem } from '../ConfigItem';
import { GlobalWalletConfig } from '../types';

export const WalletInformation: FC = () => {
  const { t } = useTranslation();
  const { errors } = useFormikContext<GlobalWalletConfig>();

  return (
    <SingleToggleCard
      accordionKey='wallet-information'
      title={t(
        'v2.page.global_wallets.domain_and_wallet_info.wallet_information.title',
      )}
      description={t(
        'v2.page.global_wallets.domain_and_wallet_info.wallet_information.description',
      )}
      allowExpand
      defaultExpanded
      customActionIcon={{
        Icon: <> </>,
      }}
    >
      <div className={styles.content}>
        <div className={styles.content__row}>
          <ConfigItem
            title={t(
              'v2.page.global_wallets.domain_and_wallet_info.wallet_information.wallet_name.title',
            )}
            description={t(
              'v2.page.global_wallets.domain_and_wallet_info.wallet_information.wallet_name.description',
            )}
          >
            <Field
              as={Input}
              className={styles.input}
              name='walletName'
              id='walletName'
              label={t(
                'v2.page.global_wallets.domain_and_wallet_info.wallet_information.wallet_name.field_label',
              )}
              placeholder={t(
                'v2.page.global_wallets.domain_and_wallet_info.wallet_information.wallet_name.field_placeholder',
              )}
              error={errors.walletName}
              errorText={errors.walletName}
            />
          </ConfigItem>
          <ConfigItem
            title={t(
              'v2.page.global_wallets.domain_and_wallet_info.wallet_information.popup_page_title.title',
            )}
            description={t(
              'v2.page.global_wallets.domain_and_wallet_info.wallet_information.popup_page_title.description',
            )}
          >
            <Field
              as={Input}
              className={styles.input}
              name='popupPageTitle'
              id='popupPageTitle'
              label={t(
                'v2.page.global_wallets.domain_and_wallet_info.wallet_information.popup_page_title.field_label',
              )}
              placeholder={t(
                'v2.page.global_wallets.domain_and_wallet_info.wallet_information.popup_page_title.field_placeholder',
              )}
              optional
              error={errors.popupPageTitle}
              errorText={errors.popupPageTitle}
            />
          </ConfigItem>
        </div>
        <div className={styles.content__row}>
          <ConfigItem
            title={t(
              'v2.page.global_wallets.domain_and_wallet_info.wallet_information.wallet_icon_url.title',
            )}
            description={t(
              'v2.page.global_wallets.domain_and_wallet_info.wallet_information.wallet_icon_url.description',
            )}
          >
            <Field
              as={Input}
              className={styles.input}
              name='walletIconUrl'
              id='walletIconUrl'
              label={t(
                'v2.page.global_wallets.domain_and_wallet_info.wallet_information.wallet_icon_url.field_label',
              )}
              placeholder={t(
                'v2.page.global_wallets.domain_and_wallet_info.wallet_information.wallet_icon_url.field_placeholder',
              )}
              optional
              error={errors.walletIconUrl}
              errorText={errors.walletIconUrl}
            />
          </ConfigItem>
        </div>
        <div className={styles.content__row}>
          <ConfigItem
            title={t(
              'v2.page.global_wallets.domain_and_wallet_info.wallet_information.custom_css_url.title',
            )}
            description={t(
              'v2.page.global_wallets.domain_and_wallet_info.wallet_information.custom_css_url.description',
            )}
          >
            <Field
              as={Input}
              className={styles.input}
              name='customCssUrl'
              id='customCssUrl'
              label={t(
                'v2.page.global_wallets.domain_and_wallet_info.wallet_information.custom_css_url.field_label',
              )}
              placeholder={t(
                'v2.page.global_wallets.domain_and_wallet_info.wallet_information.custom_css_url.field_placeholder',
              )}
              optional
              error={errors.customCssUrl}
              errorText={errors.customCssUrl}
            />
          </ConfigItem>
        </div>
      </div>
    </SingleToggleCard>
  );
};
