/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectSettingsSdkWaasOnSignUp
 */
export interface ProjectSettingsSdkWaasOnSignUp {
    /**
     * When true, backup options are shown during sign up for v3 embedded wallets.
     * @type {boolean}
     * @memberof ProjectSettingsSdkWaasOnSignUp
     */
    promptBackupOptions: boolean;
    /**
     * When true, users will be prompted to export their client share.
     * @type {boolean}
     * @memberof ProjectSettingsSdkWaasOnSignUp
     */
    promptClientShareExport: boolean;
}

export function ProjectSettingsSdkWaasOnSignUpFromJSON(json: any): ProjectSettingsSdkWaasOnSignUp {
    return ProjectSettingsSdkWaasOnSignUpFromJSONTyped(json, false);
}

export function ProjectSettingsSdkWaasOnSignUpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsSdkWaasOnSignUp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'promptBackupOptions': json['promptBackupOptions'],
        'promptClientShareExport': json['promptClientShareExport'],
    };
}

export function ProjectSettingsSdkWaasOnSignUpToJSON(value?: ProjectSettingsSdkWaasOnSignUp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'promptBackupOptions': value.promptBackupOptions,
        'promptClientShareExport': value.promptClientShareExport,
    };
}

