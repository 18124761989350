/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GlobalWalletSettingsCreateRequest
 */
export interface GlobalWalletSettingsCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettingsCreateRequest
     */
    walletName: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettingsCreateRequest
     */
    popupPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettingsCreateRequest
     */
    walletIconUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettingsCreateRequest
     */
    customCssUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettingsCreateRequest
     */
    termsOfServiceUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettingsCreateRequest
     */
    privacyPolicyUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettingsCreateRequest
     */
    termsOfServiceAndPrivacyPolicyMarkdown?: string;
}

export function GlobalWalletSettingsCreateRequestFromJSON(json: any): GlobalWalletSettingsCreateRequest {
    return GlobalWalletSettingsCreateRequestFromJSONTyped(json, false);
}

export function GlobalWalletSettingsCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalWalletSettingsCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'walletName': json['walletName'],
        'popupPageTitle': !exists(json, 'popupPageTitle') ? undefined : json['popupPageTitle'],
        'walletIconUrl': !exists(json, 'walletIconUrl') ? undefined : json['walletIconUrl'],
        'customCssUrl': !exists(json, 'customCssUrl') ? undefined : json['customCssUrl'],
        'termsOfServiceUrl': !exists(json, 'termsOfServiceUrl') ? undefined : json['termsOfServiceUrl'],
        'privacyPolicyUrl': !exists(json, 'privacyPolicyUrl') ? undefined : json['privacyPolicyUrl'],
        'termsOfServiceAndPrivacyPolicyMarkdown': !exists(json, 'termsOfServiceAndPrivacyPolicyMarkdown') ? undefined : json['termsOfServiceAndPrivacyPolicyMarkdown'],
    };
}

export function GlobalWalletSettingsCreateRequestToJSON(value?: GlobalWalletSettingsCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'walletName': value.walletName,
        'popupPageTitle': value.popupPageTitle,
        'walletIconUrl': value.walletIconUrl,
        'customCssUrl': value.customCssUrl,
        'termsOfServiceUrl': value.termsOfServiceUrl,
        'privacyPolicyUrl': value.privacyPolicyUrl,
        'termsOfServiceAndPrivacyPolicyMarkdown': value.termsOfServiceAndPrivacyPolicyMarkdown,
    };
}

