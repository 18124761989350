export type ChainName =
  | 'evm'
  | 'solana'
  | 'cosmos'
  | 'starknet'
  | 'eclipse'
  | 'sui';
type DefaultMapType = Record<string, string[]>;

export const DefaultRpcMap: Readonly<Record<ChainName, DefaultMapType>> = {
  cosmos: {
    401: ['https://rpc-cosmoshub.keplr.app'],
    402: ['https://rpc-axelar.keplr.app'],
    403: ['https://rpc-osmosis.keplr.app'],
    404: ['https://rpc.wallet.pacific-1.sei.io'],
    405: ['https://noble-rpc.polkachu.com'],
  },
  eclipse: {
    1: ['https://mainnetbeta-rpc.eclipse.xyz'],
  },
  evm: {
    1: [
      'https://eth.blinklabs.xyz/v1/fc1a8d7eca9005996650b94daf4497c3ac79084854ab3eb0dcfa684513edf36a',
    ],
    10: ['https://mainnet.optimism.io'],
    100: ['https://rpc.gnosischain.com'],
    11124: ['https://api.testnet.abs.xyz'],
    11155111: ['https://rpc.sepolia.org'],
    11155420: ['https://sepolia.optimism.io'],
    11297108109: [
      'https://palm-mainnet.infura.io/v3/3a961d6501e54add9a41aa53f15de99b',
    ],
    1284: ['https://rpc.api.moonbeam.network'],
    1287: ['https://rpc.api.moonbase.moonbeam.network'],
    130: ['https://mainnet.unichain.org'],
    1313161554: ['https://mainnet.aurora.dev'],
    1315: ['https://aeneid.storyscan.io'],
    1328: ['https://evm-rpc-testnet.sei-apis.com'],
    1329: ['https://evm-rpc.sei-apis.com	'],
    137: ['https://polygon-rpc.com'],
    13746: ['https://rpc-game7-testnet-0ilneybprf.t.conduit.xyz'],
    146: ['https://rpc.soniclabs.com'],
    1513: ['https://rpc.partner.testnet.storyprotocol.net/'],
    1514: ['https://storyscan.io'],
    1663: ['https://gobi-rpc.horizenlabs.io/ethv1'],
    17000: ['https://ethereum-holesky-rpc.publicnode.com/'],
    183: ['https://mainnet.ethernitychain.io'],
    1868: ['https://rpc.soneium.org/'],
    1890: ['https://replicator.phoenix.lightlink.io/rpc/v1'],
    195: ['https://testrpc.xlayer.tech'],
    196: ['https://rpc.xlayer.tech'],
    204: ['https://opbnb-mainnet-rpc.bnbchain.org'],
    23294: ['https://sapphire.oasis.io'],
    233: ['https://testnet.ethernitychain.io'],
    25: ['https://evm.cronos.org'],
    250: ['https://rpc.ankr.com/fantom'],
    280: ['https://testnet.era.zksync.dev'],
    2810: ['https://rpc-quicknode-holesky.morphl2.io/'],
    300: ['https://sepolia.era.zksync.dev'],
    314: ['https://api.node.glif.io'],
    314159: ['https://filecoin-calibration.chainup.net/rpc/v1'],
    324: ['https://mainnet.era.zksync.io'],
    33111: ['https://curtis.rpc.caldera.xyz/http'],
    33139: ['https://apechain.calderachain.xyz/http'],
    338: ['https://evm-t3.cronos.org'],
    34443: ['https://mainnet.mode.network'],
    393: ['https://rpc.nexus.xyz/http'],
    420: ['https://goerli.optimism.io'],
    42161: ['https://arb1.arbitrum.io/rpc'],
    421613: ['https://goerli-rollup.arbitrum.io/rpc'],
    421614: ['https://sepolia-rollup.arbitrum.io/rpc'],
    42220: ['https://forno.celo.org'],
    43111: ['https://rpc.hemi.network/rpc'],
    43114: ['https://api.avax.network/ext/bc/C/rpc'],
    48899: ['https://zircuit1.p2pify.com'],
    5: ['https://rpc.ankr.com/eth_goerli'],
    5000: ['https://rpc.mantle.xyz'],
    5003: ['https://rpc.sepolia.mantle.xyz'],
    50104: ['https://rpc.sophon.xyz'],
    5115: ['https://rpc.testnet.citrea.xyz'],
    531050104: ['https://rpc.testnet.sophon.xyz'],
    534351: ['https://sepolia-rpc.scroll.io'],
    534352: ['https://rpc.scroll.io'],
    545: ['https://testnet.evm.nodes.onflow.org'],
    56: ['https://bsc-dataseed1.binance.org'],
    5611: ['https://opbnb-testnet-rpc.bnbchain.org'],
    57054: ['https://rpc.blaze.soniclabs.com'],
    57073: ['https://rpc-gel.inkonchain.com'],
    59141: ['https://rpc.sepolia.linea.build'],
    59144: ['https://rpc.linea.build'],
    60808: ['https://bob-mainnet.public.blastapi.io'],
    6342: ['https://carrot.megaeth.com/rpc'],
    666666666: ['https://rpc.degen.tips'],
    698: ['https://rpc.matchain.io'],
    699: ['https://testnet-rpc.matchain.io'],
    713715: ['https://evm-rpc.arctic-1.seinetwork.io'],
    7332: ['https://eon-rpc.horizenlabs.io/ethv1'],
    747: ['https://mainnet.evm.nodes.onflow.org'],
    763373: ['https://rpc-gel-sepolia.inkonchain.com'],
    7668: ['https://root.rootnet.live/archive'],
    7777777: ['https://rpc.zora.energy'],
    80001: ['https://rpc.ankr.com/polygon_mumbai'],
    80002: ['https://rpc-amoy.polygon.technology'],
    80069: ['https://bepolia.rpc.berachain.com/'],
    80084: ['https://bartio.rpc.berachain.com'],
    80085: ['https://artio.rpc.berachain.com'],
    80094: ['https://rpc.berachain.com/'],
    8082: ['https://sphinx.shardeum.org/'],
    808813: ['https://bob-sepolia.rpc.gobob.xyz'],
    81457: ['https://rpc.blast.io/'],
    8453: ['https://mainnet.base.org'],
    84531: ['https://goerli.base.org'],
    84532: ['https://sepolia.base.org'],
    88888: ['https://rpc.chiliz.com'],
    911867: ['https://odyssey.ithaca.xyz'],
    919: ['https://sepolia.mode.network'],
    998: ['https://rpc.hyperliquid-testnet.xyz/evm'],
    999: ['https://rpc.hyperliquid.xyz/evm'],
    999999999: ['https://sepolia.rpc.zora.energy'],
  },

  solana: {
    101: ['https://api.mainnet-beta.solana.com'],
    102: ['https://api.devnet.solana.com'],
    103: ['https://api.testnet.solana.com'],
    201: ['https://mainnetbeta-rpc.eclipse.xyz'],
    202: ['https://testnet.dev2.eclipsenetwork.xyz'],
  },
  starknet: {
    410: ['https://starknet-mainnet.public.blastapi.io'],
    411: ['https://starknet-testnet.public.blastapi.io'],
    412: ['https://starknet-sepolia.public.blastapi.io'],
  },
  sui: {
    501: ['https://fullnode.mainnet.sui.io'],
    502: ['https://fullnode.testnet.sui.io'],
    503: ['https://fullnode.devnet.sui.io'],
  },
};
