import React, { forwardRef, useCallback, useId, useRef } from 'react';

import classNames from 'classnames';

import { Typography } from '../Typography';

import styles from './TextArea.module.scss';

export type TextAreaProps = React.ComponentPropsWithoutRef<'textarea'> & {
  ariaLabel?: string;
  autofocus?: boolean;
  className?: string;
  cols?: number;
  error?: boolean;
  errorText?: string;
  label?: string;
  maskDatadogValue?: boolean;
  padding?: 'small' | 'regular';
  placeholder?: string;
  readOnly?: boolean;
  rows?: number;
  textAreaClassName?: string;
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className = '',
      cols = 1,
      disabled,
      error = false,
      errorText,
      id,
      label,
      placeholder,
      onChange,
      onBlur,
      name,
      rows = 3,
      value,
      textAreaClassName,
      maskDatadogValue,
      readOnly,
      padding = 'regular',
      ariaLabel,
      autofocus,
    },
    forwardedRef,
  ) => {
    const hasTriggeredAutofocus = useRef(false);

    const errorTextId = useId();

    // We use a custom ref so that we can also manipulate it
    const ref = useCallback(
      (instance: HTMLTextAreaElement | null) => {
        // Trigger autofocus
        if (autofocus && instance && !hasTriggeredAutofocus.current) {
          instance.focus();
          hasTriggeredAutofocus.current = true;
        }

        // Assign forwarded ref
        if (forwardedRef) {
          if (typeof forwardedRef === 'function') {
            forwardedRef(instance);
          } else {
            forwardedRef.current = instance;
          }
        }
      },
      [autofocus, forwardedRef],
    );

    return (
      <div className={classNames(className, styles.wrapper)}>
        <textarea
          cols={cols}
          rows={rows}
          id={id || name}
          disabled={disabled}
          readOnly={readOnly}
          aria-label={label === undefined ? ariaLabel : undefined}
          aria-describedby={`${errorTextId}`}
          data-testid={`textarea-${id || name}`}
          data-dd-privacy={maskDatadogValue ? 'mask' : undefined}
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          placeholder={placeholder || label}
          ref={ref}
          value={value}
          className={classNames(
            styles.textarea,
            styles[`textarea--padding-${padding}`],
            {
              [styles['textarea--with-label']]: label,
              [styles['textarea--error']]: error,
            },
            textAreaClassName,
          )}
        />
        <label
          className={classNames(
            styles.label,
            styles[`label--padding-${padding}`],
            {
              [styles['label--error']]: error,
            },
          )}
          htmlFor={id || name}
          data-testid={`textarea-label-${id || name}`}
        >
          <span className={styles.placeholder__text}>
            {placeholder || label}
          </span>
          <span className={styles.label__text}>{label}</span>
        </label>
        {errorText && (
          <Typography
            id={errorTextId}
            variant='paragraph-1'
            color='inherit'
            className={classNames(
              styles.errorText,
              styles[`errorText--padding-${padding}`],
            )}
          >
            {errorText}
          </Typography>
        )}
      </div>
    );
  },
);
