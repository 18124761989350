import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import { AnimatePresence } from 'framer-motion';

import {
  SingleToggleCard,
  Input,
  Tab,
  Tabs,
  TextArea,
} from '@dynamic-labs/northstar';

import styles from '../GlobalWalletDomainSection.module.scss';
import { ConfigItem } from '../ConfigItem';
import { GlobalWalletConfig } from '../types';

type TermsOfServiceAndPrivicyPolicyProps = {
  hasCustomMarkdown: boolean;
};

export const TermsOfServiceAndPrivicyPolicy: FC<
  TermsOfServiceAndPrivicyPolicyProps
> = ({ hasCustomMarkdown }) => {
  const { t } = useTranslation();
  const { errors } = useFormikContext<GlobalWalletConfig>();

  const tabs: Tab[] = [
    {
      id: 'basic',
      label: t(
        'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.tabs.basic',
      ),
    },
    {
      id: 'advanced',
      label: t(
        'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.tabs.advanced',
      ),
    },
  ];

  const getInitialActiveTabId = () => {
    if (hasCustomMarkdown) {
      return tabs[1].id;
    }

    return tabs[0].id;
  };

  const [activeTabId, setActiveTabId] = useState(getInitialActiveTabId());

  const renderSection = (tabId: string) => {
    if (tabId === 'advanced') {
      return (
        <ConfigItem
          title={t(
            'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.privacy_policy_markdown.title',
          )}
          description={t(
            'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.privacy_policy_markdown.description',
          )}
        >
          <Field
            as={TextArea}
            className={styles.textarea}
            name='termsOfServiceAndPrivacyPolicyMarkdown'
            id='termsOfServiceAndPrivacyPolicyMarkdown'
            label={t(
              'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.privacy_policy_markdown.field_label',
            )}
            placeholder={t(
              'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.privacy_policy_markdown.field_placeholder',
            )}
            optional
            error={errors.termsOfServiceAndPrivacyPolicyMarkdown}
            errorText={errors.termsOfServiceAndPrivacyPolicyMarkdown}
            rows={6}
          />
        </ConfigItem>
      );
    }
    return (
      <>
        <ConfigItem
          title={t(
            'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.terms_of_service_url.title',
          )}
          description={t(
            'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.terms_of_service_url.description',
          )}
        >
          <Field
            as={Input}
            className={styles.input}
            name='termsOfServiceUrl'
            id='termsOfServiceUrl'
            label={t(
              'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.terms_of_service_url.field_label',
            )}
            placeholder={t(
              'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.terms_of_service_url.field_placeholder',
            )}
            optional
            error={errors.termsOfServiceUrl}
            errorText={errors.termsOfServiceUrl}
          />
        </ConfigItem>
        <ConfigItem
          title={t(
            'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.privacy_policy_url.title',
          )}
          description={t(
            'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.privacy_policy_url.description',
          )}
        >
          <Field
            as={Input}
            className={styles.input}
            name='privacyPolicyUrl'
            id='privacyPolicyUrl'
            label={t(
              'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.privacy_policy_url.field_label',
            )}
            placeholder={t(
              'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.privacy_policy_url.field_placeholder',
            )}
            optional
            error={errors.privacyPolicyUrl}
            errorText={errors.privacyPolicyUrl}
          />
        </ConfigItem>
      </>
    );
  };

  return (
    <SingleToggleCard
      accordionKey='wallet-information'
      title={t(
        'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.title',
      )}
      description={t(
        'v2.page.global_wallets.domain_and_wallet_info.tos_and_pp.description',
      )}
      allowExpand
      defaultExpanded
      customActionIcon={{
        Icon: <> </>,
      }}
    >
      <div className={styles.content}>
        <Tabs
          tabs={tabs}
          activeTabId={activeTabId}
          setActiveTabId={setActiveTabId}
          variant='button-secondary'
        />
        <AnimatePresence initial={false} mode='wait'>
          {renderSection(activeTabId)}
        </AnimatePresence>
      </div>
    </SingleToggleCard>
  );
};
