/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum GlobalWalletAccessControlTypeEnum {
    Allow = 'allow',
    Block = 'block'
}

export function GlobalWalletAccessControlTypeEnumFromJSON(json: any): GlobalWalletAccessControlTypeEnum {
    return GlobalWalletAccessControlTypeEnumFromJSONTyped(json, false);
}

export function GlobalWalletAccessControlTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalWalletAccessControlTypeEnum {
    return json as GlobalWalletAccessControlTypeEnum;
}

export function GlobalWalletAccessControlTypeEnumToJSON(value?: GlobalWalletAccessControlTypeEnum | null): any {
    return value as any;
}

