import { Provider, ProviderEnum } from '@dynamic-labs/sdk-api';

import { settingsApi } from '../../../../../services/api';
import { createProviderUpdateRequest } from '../createProviderUpdateRequest';

export const updateProviders = (
  environmentId: string,
  savedProviders: Record<ProviderEnum, Provider>,
  providers: Provider[],
) =>
  Promise.all(
    providers.map(async (provider) => {
      if (!provider.id) {
        // Create new provider
        const createdProvider = await settingsApi.createProvider({
          environmentId,
          providerCreateRequest: {
            accountSid: provider.accountSid,
            appleKeyId: provider.appleKeyId,
            appleTeamId: provider.appleTeamId,
            clientId: provider.clientId,
            clientSecret: provider.clientSecret,
            defaultChain: provider.defaultChain,
            ecdsaProviderType: provider.ecdsaProviderType,
            enableEIP7702: provider.enableEIP7702,
            enableKernelV3Migration: provider.enableKernelV3Migration,
            enabledCountries: provider.enabledCountries,
            entryPointVersion: provider.entryPointVersion,
            factoryAddress: provider.factoryAddress,
            kernelVersion: provider.kernelVersion,
            keyExportUrl: provider.keyExportUrl,
            multichainAccountAbstractionProviders:
              provider.multichainAccountAbstractionProviders,
            paymasterAddress: provider.paymasterAddress,
            provider: provider.provider,
            providerProjectId: provider.providerProjectId,
            scopes: provider.scopes,
            shopifyStore: provider.shopifyStore,
            twilioNumber: provider.twilioNumber,
          },
        });

        if (!!provider.enabledAt && createdProvider.id) {
          await settingsApi.enableProvider({
            providerId: createdProvider.id,
          });
        }
      } else {
        // Update provider
        await settingsApi.updateProvider({
          providerId: provider.id,
          providerUpdateRequest: createProviderUpdateRequest(
            savedProviders[provider.provider],
            provider,
          ),
        });

        const isProviderEnabledInApi =
          !!savedProviders[provider.provider].enabledAt;

        const isProviderEnabled = !!provider.enabledAt;

        if (isProviderEnabled && !isProviderEnabledInApi) {
          // enable
          await settingsApi.enableProvider({
            providerId: provider.id,
          });
        }

        if (!isProviderEnabled && isProviderEnabledInApi) {
          // disable
          await settingsApi.disableProvider({
            providerId: provider.id,
          });
        }
      }
    }),
  );
