/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WaasBackupOptionsEnum {
    GoogleDrive = 'googleDrive',
    Icloud = 'icloud'
}

export function WaasBackupOptionsEnumFromJSON(json: any): WaasBackupOptionsEnum {
    return WaasBackupOptionsEnumFromJSONTyped(json, false);
}

export function WaasBackupOptionsEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaasBackupOptionsEnum {
    return json as WaasBackupOptionsEnum;
}

export function WaasBackupOptionsEnumToJSON(value?: WaasBackupOptionsEnum | null): any {
    return value as any;
}

