import {
  CSSProperties,
  FC,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import { TextButton, TextButtonProps } from '../TextButton';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

import styles from './Tabs.module.scss';

export type Tab = {
  id: string;
  label: string;
};

type Info = {
  Icon: ReactElement;
  action?: Omit<
    TextButtonProps,
    'variant' | 'weight' | 'trailing' | 'leading' | 'className' | 'color'
  >;
  text: string;
};

export type TabsProps = {
  activeTabId: string;
  disabled?: boolean;
  info?: Info;
  onDisabledClick?: () => void;
  setActiveTabId: (tabId: string) => void;
  tabs: Tab[];
  variant?: 'underline' | 'button-primary' | 'button-secondary';
};

export const Tabs: FC<TabsProps> = ({
  tabs,
  activeTabId,
  setActiveTabId,
  info,
  disabled,
  onDisabledClick,
  variant = 'underline',
}) => {
  const itemsRef = useRef<Array<HTMLSpanElement | null>>([]);
  const [activeBubbleStyles, setActiveBubbleStyles] = useState<CSSProperties>(
    {},
  );

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, tabs.length);
  }, [tabs]);

  useLayoutEffect(() => {
    const activeItemIndex = tabs.findIndex((tab) => tab.id === activeTabId);

    const activeItemRef = itemsRef.current[activeItemIndex];

    if (activeItemRef) {
      setActiveBubbleStyles({
        left: `${activeItemRef.offsetLeft}px`,
        width: `${activeItemRef.offsetWidth}px`,
      });
    }
  }, [activeTabId, tabs]);

  if (tabs.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, styles[`container--${variant}`])}
    >
      <div className={styles.tabs}>
        {tabs.map((tab, index) => (
          <span
            className={styles.tab}
            key={tab.id}
            ref={(el) => {
              itemsRef.current[index] = el;
            }}
          >
            <TextButton
              dataTestId={`tab-${tab.id}`}
              variant='button-medium'
              text={tab.label}
              className={classNames(styles.tab__inner, {
                [styles['tab__inner--active']]: tab.id === activeTabId,
                [styles['tab__inner--disabled']]: disabled,
              })}
              color='inherit'
              onClick={() =>
                disabled ? onDisabledClick?.() : setActiveTabId(tab.id)
              }
            />
          </span>
        ))}
        {variant === 'underline' && (
          <div style={activeBubbleStyles} className={styles.bubble} />
        )}
      </div>

      {info && (
        <div className={styles.info}>
          <Icon icon={info.Icon} size={20} color='primary-1' />
          <Typography variant='paragraph-2' weight='medium' color='gray-1'>
            {info.text}
          </Typography>
          {info.action && (
            <TextButton
              text={info.action.text}
              onClick={info.action.onClick}
              as={info.action.as}
              href={info.action.href}
              variant='paragraph-2'
              weight='bold'
              color='primary-1'
              className={styles.action}
            />
          )}
        </div>
      )}
    </div>
  );
};
