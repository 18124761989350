import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import type {
  IntegrationSetting,
  Network,
  ProjectSettingsChains,
} from '@dynamic-labs/sdk-api';

import { Toggle } from '../../../../components/Toggle';
import { Typography } from '../../../../components/Typography';
import NetworkContent from '../NetworkContent/NetworkContent';

import sortNetworks from './sortNetworks/sortNetworks';

interface ChainContentProps {
  chainSetting: ProjectSettingsChains;
  initialChainSetting: ProjectSettingsChains;
  networkMessage?: string;
  requireCustomRpcUrl?: boolean;
  setSingleSetting: (newSetting: IntegrationSetting) => void;
}

const ChainContent = ({
  initialChainSetting,
  chainSetting,
  setSingleSetting,
  networkMessage,
  requireCustomRpcUrl,
}: ChainContentProps) => {
  const { t } = useTranslation();
  const { deprecatedChains, enableSvm } = useFlags();
  const networks = chainSetting.networks || [];

  const orderedNetworks = sortNetworks(networks);
  const isStarknet = chainSetting.name === 'starknet';
  const isSui = chainSetting.name === 'sui';
  const isSingleNetwork = networks.length === 1;

  const networksToShow = orderedNetworks.filter((network) => {
    // we always want to show the network toggle if:
    // - the network is already enabled
    // - the chain is not solana
    // - the network is solana mainnet
    if (
      network.enabled ||
      chainSetting.name !== 'solana' ||
      network.networkId === 101
    ) {
      return true;
    }

    // otherwise, if it's a solana network but not mainnet or already enabled,
    // we only show it if the flag is enabled
    return enableSvm;
  });

  const showMultipleNetworksWarning =
    networksToShow.length > 1 && !isStarknet && !isSui;

  const handleSetSetting = (newSetting: ProjectSettingsChains) => {
    // Always sort networks before saving
    const sortedNetworks = sortNetworks(newSetting.networks || []);
    setSingleSetting({
      ...newSetting,
      networks: sortedNetworks,
    } as ProjectSettingsChains);
  };

  return (
    <div className='border border-cloud-2 p-4 rounded-xl'>
      {!isSingleNetwork && (
        <div className='flex items-center space-x-4 justify-between flex-wrap'>
          <div className='flex space-x-4 items-center flex-wrap'>
            <div className='w-9 h-5'>
              <Toggle
                withIcon
                variant={
                  initialChainSetting.enabled === chainSetting.enabled
                    ? 'success'
                    : 'primary'
                }
                className=''
                id={`toggle-chain-${chainSetting.name}-activation`}
                checked={chainSetting.enabled}
                handleChange={() =>
                  handleSetSetting({
                    ...chainSetting,
                    enabled: !chainSetting.enabled,
                  })
                }
                ariaLabel={t('integrations.chains.toggle.aria_label')}
              />
            </div>
            <div className='text-sm'>
              {t('integrations.chains.toggle.label')}
            </div>
          </div>
        </div>
      )}

      {networks.length > 0 && (
        <div
          className={classNames('flex flex-col', { 'mt-4': !isSingleNetwork })}
        >
          {!isSingleNetwork && (
            <div className='text-gray-1 text-sm font-normal'>
              {t('integrations.chains.networks.subtitle')}
            </div>
          )}

          {networksToShow.map((network: Network, index) => (
            <NetworkContent
              key={`networkContent-${network.networkId}`}
              initialChainSetting={initialChainSetting}
              isExpanded={index === 0 || !!requireCustomRpcUrl}
              chainSetting={chainSetting}
              network={network}
              setSetting={handleSetSetting}
              isSingleNetwork={isSingleNetwork}
              requireCustomRpcUrl={requireCustomRpcUrl}
              deprecated={(deprecatedChains || []).includes(network.networkId)}
            />
          ))}

          {showMultipleNetworksWarning && (
            <div className='p-3 bg-yellow-4 rounded-md text-yellow-1'>
              <div className='text-sm font-bold'>
                {t('integrations.chains.networks.warning_title')}
              </div>
              <div className='text-xs'>
                {t('integrations.chains.networks.warning_content')}
              </div>
            </div>
          )}

          {networkMessage && (
            <Typography
              variant='paragraph-1'
              className='p-2 my-2 bg-primary-4 rounded-lg'
            >
              {networkMessage}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default ChainContent;
