/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OpenRoomResponse,
    OpenRoomResponseFromJSON,
    OpenRoomResponseFromJSONTyped,
    OpenRoomResponseToJSON,
} from './OpenRoomResponse';

/**
 * 
 * @export
 * @interface OpenRoomResponseWithServerKeygenIds
 */
export interface OpenRoomResponseWithServerKeygenIds {
    /**
     * 
     * @type {string}
     * @memberof OpenRoomResponseWithServerKeygenIds
     */
    roomId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpenRoomResponseWithServerKeygenIds
     */
    serverKeygenIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpenRoomResponseWithServerKeygenIds
     */
    newServerKeygenIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OpenRoomResponseWithServerKeygenIds
     */
    walletId?: string;
}

export function OpenRoomResponseWithServerKeygenIdsFromJSON(json: any): OpenRoomResponseWithServerKeygenIds {
    return OpenRoomResponseWithServerKeygenIdsFromJSONTyped(json, false);
}

export function OpenRoomResponseWithServerKeygenIdsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenRoomResponseWithServerKeygenIds {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomId': json['roomId'],
        'serverKeygenIds': json['serverKeygenIds'],
        'newServerKeygenIds': !exists(json, 'newServerKeygenIds') ? undefined : json['newServerKeygenIds'],
        'walletId': !exists(json, 'walletId') ? undefined : json['walletId'],
    };
}

export function OpenRoomResponseWithServerKeygenIdsToJSON(value?: OpenRoomResponseWithServerKeygenIds | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roomId': value.roomId,
        'serverKeygenIds': value.serverKeygenIds,
        'newServerKeygenIds': value.newServerKeygenIds,
        'walletId': value.walletId,
    };
}

