/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectSettingsSdkWaasOnSignUp,
    ProjectSettingsSdkWaasOnSignUpFromJSON,
    ProjectSettingsSdkWaasOnSignUpFromJSONTyped,
    ProjectSettingsSdkWaasOnSignUpToJSON,
} from './ProjectSettingsSdkWaasOnSignUp';
import {
    WaasBackupOptionsEnum,
    WaasBackupOptionsEnumFromJSON,
    WaasBackupOptionsEnumFromJSONTyped,
    WaasBackupOptionsEnumToJSON,
} from './WaasBackupOptionsEnum';

/**
 * 
 * @export
 * @interface ProjectSettingsSdkWaas
 */
export interface ProjectSettingsSdkWaas {
    /**
     * When true, passcode security method is forced for v3 embedded wallets. Usually it is an optional security method.
     * @type {boolean}
     * @memberof ProjectSettingsSdkWaas
     */
    passcodeRequired: boolean;
    /**
     * 
     * @type {ProjectSettingsSdkWaasOnSignUp}
     * @memberof ProjectSettingsSdkWaas
     */
    onSignUp: ProjectSettingsSdkWaasOnSignUp;
    /**
     * 
     * @type {Array<WaasBackupOptionsEnum>}
     * @memberof ProjectSettingsSdkWaas
     */
    backupOptions: Array<WaasBackupOptionsEnum>;
    /**
     * The URL of the relay server for the environment. If not provided, the default relay server will be used.
     * @type {string}
     * @memberof ProjectSettingsSdkWaas
     */
    relayUrl?: string;
}

export function ProjectSettingsSdkWaasFromJSON(json: any): ProjectSettingsSdkWaas {
    return ProjectSettingsSdkWaasFromJSONTyped(json, false);
}

export function ProjectSettingsSdkWaasFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsSdkWaas {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'passcodeRequired': json['passcodeRequired'],
        'onSignUp': ProjectSettingsSdkWaasOnSignUpFromJSON(json['onSignUp']),
        'backupOptions': ((json['backupOptions'] as Array<any>).map(WaasBackupOptionsEnumFromJSON)),
        'relayUrl': !exists(json, 'relayUrl') ? undefined : json['relayUrl'],
    };
}

export function ProjectSettingsSdkWaasToJSON(value?: ProjectSettingsSdkWaas | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'passcodeRequired': value.passcodeRequired,
        'onSignUp': ProjectSettingsSdkWaasOnSignUpToJSON(value.onSignUp),
        'backupOptions': ((value.backupOptions as Array<any>).map(WaasBackupOptionsEnumToJSON)),
        'relayUrl': value.relayUrl,
    };
}

