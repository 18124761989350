import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import type {
  IntegrationSetting,
  Network,
  ProjectSettingsChains,
} from '@dynamic-labs/sdk-api';

import type { RpcTestResult } from '../../../../types';
import {
  testEvmRpcUrl,
  testSolanaRpcUrl,
  testStarknetRpcUrl,
} from '../../../../utils/testRpcUrls';
import { ArrowIcon } from '../../../../../icons';
import { getNetworkIcon } from '../../../../utils/getNetworkIcon';
import { Toggle } from '../../../../components/Toggle';
import { DefaultRpcMap, type ChainName } from '../../../../data/DefaultRpcUrls';

import styles from './NetworkContent.module.css';
import RpcInputForm from './RpcInputForm';

interface NetworkContentProps {
  chainSetting: ProjectSettingsChains;
  deprecated?: boolean;
  initialChainSetting: ProjectSettingsChains;
  isExpanded: boolean;
  /** Whether this is the only network of the chain */
  isSingleNetwork: boolean;
  network: Network;
  requireCustomRpcUrl?: boolean;
  setSetting: (newSetting: IntegrationSetting) => void;
}

const NetworkContent = ({
  chainSetting,
  initialChainSetting,
  network,
  setSetting,
  isExpanded: defaultExpanded,
  isSingleNetwork,
  requireCustomRpcUrl,
  deprecated,
}: NetworkContentProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [loading, setLoading] = useState(false);
  const [testResult, setTestResult] = useState<RpcTestResult>();
  const NetworkIcon = getNetworkIcon(network.networkId, network.iconUrl);

  const [defaultRpcUrl] = DefaultRpcMap[chainSetting.name as ChainName]?.[
    network.networkId
  ] || [''];
  const hasCustomRpcUrl = network.rpcUrl ? network.rpcUrl.length > 0 : false;
  const toggleIsChecked = network.enabled && chainSetting.enabled;
  const disableToggle =
    (!chainSetting.enabled && !isSingleNetwork) ||
    (requireCustomRpcUrl && !hasCustomRpcUrl && !toggleIsChecked) ||
    (!toggleIsChecked && deprecated);

  const initialNetwork = initialChainSetting.networks?.find(
    (n) => n.networkId === network.networkId,
  );
  const isInitialState =
    initialNetwork?.enabled === network.enabled &&
    initialChainSetting.enabled === chainSetting.enabled;

  const handleNetworkUpdate = useCallback(
    (updates: Partial<Network>) => {
      const updatedNetworks = (chainSetting.networks || []).map((n) =>
        n.networkId === network.networkId ? { ...n, ...updates } : n,
      );

      const updatedChain = {
        ...chainSetting,
        networks: updatedNetworks,
        ...(isSingleNetwork && 'enabled' in updates
          ? { enabled: updates.enabled }
          : {}),
      };

      setSetting(updatedChain);
    },
    [chainSetting, network.networkId, isSingleNetwork, setSetting],
  );

  const handleToggle = useCallback(() => {
    handleNetworkUpdate({ enabled: !network.enabled });
  }, [network.enabled, handleNetworkUpdate]);

  const handleRpcUrlChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newUrl = e.target.value;
      if (newUrl.length === 0 && requireCustomRpcUrl && network.enabled) {
        handleNetworkUpdate({ enabled: false });
      }
      handleNetworkUpdate({ rpcUrl: newUrl });
    },
    [network.enabled, requireCustomRpcUrl, handleNetworkUpdate],
  );

  const testUrl = useCallback(
    (url: string) => {
      if (loading) return;

      setLoading(true);
      const verifier = {
        eclipse: testSolanaRpcUrl,
        evm: testEvmRpcUrl,
        solana: testSolanaRpcUrl,
        starknet: testStarknetRpcUrl,
      }[chainSetting.name];

      if (!verifier) {
        setTestResult({
          message: t('integrations.rpcUrls.failed'),
          success: false,
        });
        setLoading(false);
        return;
      }

      verifier({ networkId: network.networkId, rpcUrl: url })
        .then((response) => {
          setTestResult({
            message: t(
              response
                ? 'integrations.rpcUrls.successful'
                : 'integrations.rpcUrls.failed',
            ),
            success: response,
          });
        })
        .finally(() => setLoading(false));
    },
    [chainSetting.name, loading, network.networkId, t],
  );

  if (deprecated && !toggleIsChecked) return null;

  return (
    <div
      className={classNames('flex flex-col border-cloud', {
        'border-b py-3': !isSingleNetwork,
      })}
    >
      <div className='flex items-center flex-wrap'>
        <div className='w-9 h-5'>
          <Toggle
            disabled={disableToggle}
            withIcon
            variant={isInitialState ? 'success' : 'primary'}
            className=''
            id={`toggle-network-${network.chainName}-activation`}
            handleChange={handleToggle}
            checked={toggleIsChecked}
            ariaLabel={t(
              `integrations.chains.networks.toggle${
                deprecated ? '_deprecated' : ''
              }`,
              {
                network: network.chainName,
              },
            )}
          />
        </div>

        {!isSingleNetwork && (
          <div className='w-6 h-6 shrink-0 rounded-full ml-4 mr-2'>
            <NetworkIcon
              title={`${network.chainName}-icon`}
              className='w-6 h-6'
            />
          </div>
        )}

        <div
          className={classNames('mr-2 text-sm', { 'ml-4': isSingleNetwork })}
        >
          {isSingleNetwork
            ? t('integrations.chains.toggle.label')
            : network.chainName}
          {deprecated && ` (${t('integrations.deprecated')})`}
        </div>

        <div className={`mr-2 text-sm ${styles.network__expand}`}>
          <button
            aria-label='Collapse list view'
            className={`${styles.button__expand} ${
              expanded ? styles.button__expanded : ''
            }`}
            type='button'
            onClick={() => setExpanded(!expanded)}
          >
            <ArrowIcon className={expanded ? styles.icon__expanded : ''} />
          </button>
        </div>

        {expanded && (
          <RpcInputForm
            key={`rpc-${network.chainName}`}
            testUrl={testUrl}
            testResult={testResult}
            value={network.rpcUrl || ''}
            chainName={network.chainName}
            disabled={loading}
            label={requireCustomRpcUrl ? '' : defaultRpcUrl}
            defaultRpcUrl={defaultRpcUrl}
            onChange={handleRpcUrlChange}
            requireCustomRpcUrl={requireCustomRpcUrl}
          />
        )}
      </div>
    </div>
  );
};

export default NetworkContent;
