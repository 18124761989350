import { FormikErrors } from 'formik';
import { t } from 'i18next';

export type InputsErrors = FormikErrors<{
  address: string;
  discordUsername: string;
  email: string;
  emailDomain: string;
  farcasterFid: number;
  phoneNumber: string;
  twitterUsername: string;
}>;

export const buildErrorsInfo = (errors: InputsErrors) => {
  const formatError =
    (errors.email === 'invalidFormat' ||
      errors.emailDomain === 'invalidFormat') &&
    t(
      'integrations.onboarding_and_kyc.access_control.access_list.format_error_text',
    );

  const farcasterInvalidNumberError =
    errors.farcasterFid === 'invalidNumber' &&
    t(
      'integrations.onboarding_and_kyc.access_control.access_list.invalid_number_error_text',
    );

  const requiredError =
    !farcasterInvalidNumberError &&
    (errors.address === 'required' ||
      errors.email === 'required' ||
      errors.emailDomain === 'required' ||
      errors.phoneNumber === 'required' ||
      errors.discordUsername === 'required' ||
      errors.twitterUsername === 'required' ||
      errors.farcasterFid === 'required') &&
    t(
      'integrations.onboarding_and_kyc.access_control.access_list.required_error_text',
    );

  const duplicatedName =
    errors.address === 'duplicatedName' &&
    t(
      'integrations.onboarding_and_kyc.access_control.access_list.duplicate_address_error',
    );

  return {
    duplicatedName,
    farcasterInvalidNumberError,
    formatError,
    requiredError,
  };
};
