import type { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Section } from '@dynamic-labs/northstar';

import { CoinbaseCard } from './CoinbaseCard';
import { BanxaCard } from './BanxaCard';
import styles from './OnrampProviders.module.scss';

export const OnrampProviders: FC = () => {
  const { t } = useTranslation();
  const { enableCoinbaseOnramp } = useFlags();

  return (
    <Section
      title={t('v2.page.funding.sections.funding_providers.title')}
      description={t('v2.page.funding.sections.funding_providers.description')}
    >
      <div className={styles.section}>
        <BanxaCard />
        {enableCoinbaseOnramp && <CoinbaseCard />}
      </div>
    </Section>
  );
};
